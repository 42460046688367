import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._cyvdpqrdgtiiowqutmdrcifqpe/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.9_magicast@0.3.5_rollup@4.28.1_ts-node@10.9.2_@swc+core@1.10.0_@_myznqqcy7vfkzgarwrmzdvh6ky/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}