import payload_plugin_cE4j0C6J4M from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.28.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payload_plugin_jDsspeP8IF from "/app/node_modules/.pnpm/@pinia+colada-nuxt@0.0.4_@pinia+colada@0.13.0_magicast@0.3.5_rollup@4.28.1/node_modules/@pinia/colada-nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_zdMtfSMM6y from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._cyvdpqrdgtiiowqutmdrcifqpe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zHvnR8V9hY from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._cyvdpqrdgtiiowqutmdrcifqpe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_s8VPcyZwnW from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._cyvdpqrdgtiiowqutmdrcifqpe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rGHUGxw5Az from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._cyvdpqrdgtiiowqutmdrcifqpe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lLfwRExAyx from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._cyvdpqrdgtiiowqutmdrcifqpe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DWaWZSrN0Y from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._cyvdpqrdgtiiowqutmdrcifqpe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_RktjwkJbkk from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._cyvdpqrdgtiiowqutmdrcifqpe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fSIBWaMpBq from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.28.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_mfvOiUrGSn from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._cyvdpqrdgtiiowqutmdrcifqpe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_aZCeYc6T3z from "/app/node_modules/.pnpm/@pinia+colada-nuxt@0.0.4_@pinia+colada@0.13.0_magicast@0.3.5_rollup@4.28.1/node_modules/@pinia/colada-nuxt/dist/runtime/plugin.js";
import session_client_AB1Q1ezST9 from "/app/node_modules/.pnpm/nuxt-auth-utils@0.5.5_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import slideovers_rV4mdyfhmX from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.9_magicast@0.3.5_rollup@4.28.1_ts-node@10.9.2_@swc+core@1.10.0_@swc_r3knukmpkvequkq67alqvfsp4q/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_q8Tqjo2Tfz from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.9_magicast@0.3.5_rollup@4.28.1_ts-node@10.9.2_@swc+core@1.10.0_@swc_r3knukmpkvequkq67alqvfsp4q/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_PzFlqCvhjV from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.9_magicast@0.3.5_rollup@4.28.1_ts-node@10.9.2_@swc+core@1.10.0_@swc_r3knukmpkvequkq67alqvfsp4q/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_v9jhAPblMl from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.28.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_8eDABu97Fw from "/app/node_modules/.pnpm/@nuxt+icon@1.9.1_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_terser@5.37.0___tgr6aeimbot4trsugze2e7inka/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_adQ8chEseh from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.9_magicast@0.3.5_rollup@4.28.1_ts-node@10.9.2_@swc+core@1.10.0_@_myznqqcy7vfkzgarwrmzdvh6ky/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_iAjv9LvM66 from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.9_magicast@0.3.5_rollup@4.28.1_ts-node@10.9.2_@swc+core@1.10.0_@_myznqqcy7vfkzgarwrmzdvh6ky/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_iW0eReh6tt from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_axios@1.7.9_magicast@0.3.5_rollup@4.28.1_ts-node@10.9.2_@swc+core@1.10.0_@_myznqqcy7vfkzgarwrmzdvh6ky/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import sentry_client_a4hAB0MOGi from "/app/apps/app/app/plugins/sentry.client.ts";
export default [
  payload_plugin_cE4j0C6J4M,
  payload_plugin_jDsspeP8IF,
  revive_payload_client_zdMtfSMM6y,
  unhead_zHvnR8V9hY,
  router_s8VPcyZwnW,
  payload_client_rGHUGxw5Az,
  navigation_repaint_client_lLfwRExAyx,
  check_outdated_build_client_DWaWZSrN0Y,
  chunk_reload_client_RktjwkJbkk,
  plugin_vue3_fSIBWaMpBq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mfvOiUrGSn,
  plugin_aZCeYc6T3z,
  session_client_AB1Q1ezST9,
  slideovers_rV4mdyfhmX,
  modals_q8Tqjo2Tfz,
  colors_PzFlqCvhjV,
  plugin_client_v9jhAPblMl,
  plugin_8eDABu97Fw,
  scrollbars_client_adQ8chEseh,
  presets_iAjv9LvM66,
  variables_iW0eReh6tt,
  sentry_client_a4hAB0MOGi
]